import { apiPost } from "../../utilFunctions/apiRequests";
import { ManagerVerbs } from "../../utilFunctions/managerVerbs";
import { handleMessages } from "../../utilFunctions/handleMessages";

export const getConversation = async (
  clientName,
  phone,
  startTimestamp,
  endTimestamp,
  accessToken,
  logout
) => {
  const { REACT_APP_STAGE, REACT_APP_WHATSAPP_API } = process.env;
  const messagesDynamoDB = await apiPost(
    `/whatsapp-public-api-${REACT_APP_STAGE}/manager`,
    {
      clientName,
      phone,
      verb: ManagerVerbs.GetMessagesByPhoneAndTimestamp,
      start: startTimestamp,
      end: endTimestamp,
    },
    accessToken,
    logout,
    REACT_APP_WHATSAPP_API
  )
    .then((data) => data)
    .catch((err) => console.log(err));

  if (!messagesDynamoDB.length) throw new Error("Não existe mensagens");
  const formatedMessages = handleMessages(
    messagesDynamoDB,
    startTimestamp,
    endTimestamp
  );

  return Object.values(formatedMessages)[0];
};
