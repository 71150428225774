// MuveListaEntregadores.js

import React, { useState, useEffect } from "react";
import { Box, useTheme, Typography } from "@mui/material";
import Header from "../../layoutComponents/Header";
import { useSelector } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import StatBox from "../../boxComponents/StatBox";
import PaidIcon from "@mui/icons-material/Paid";
import { prettyNumber } from "../../utilFunctions/mathUtils";
import { apiPost } from "../../utilFunctions/apiRequests";
import Table from "../../tableComponents/Table";

const MuveListaEntregadores = () => {
	const theme = useTheme();
	const { logout } = useAuth0();
	const accessToken = useSelector((state) => state.auth0.accessToken);

	const [romaneioAntecipacaoViz, setRomaneioAntecipacaoViz] = useState(null);
	const [error, setError] = useState(null);

	useEffect(() => {
		if (accessToken) {
			apiPost("/romaneioCadastroView", { ops: "muve" }, accessToken, logout)
				.then((value) => {
					setRomaneioAntecipacaoViz(value);
					setError(null);
				})
				.catch((err) => {
					console.error(err);
					setError("Erro ao carregar as informações.");
				});
		}
	}, [accessToken, logout]);

	const columnsOfAntecipacoesTable = [
		{ field: "id", headerName: "ID", flex: 1, hide: true },
		{ field: "nome_completo", headerName: "Nome Completo", flex: 1 },
		{
			field: "numero_documento",
			headerName: "CPF",
			flex: 1,
		},
		{
			field: "telefone",
			headerName: "Telefone",
		},
	];

	return (
		<>
			<Box
				display="grid"
				gridTemplateColumns="repeat(12,minmax(100px, 1fr))"
				gap="20px"
				mt="60px"
				marginBottom="40px"
				position
				gridAutoRows="0"
			>
				<Box gridColumn={`span 6`} display="flex" justifyContent="space-between" alignItems="center">
					<Header title="Lista Entregadores" subtitle="Lista de todos os entregadores cadastrados" />
				</Box>
			</Box>

			{error && (
				<Box color="red" mb="20px">
					<Typography variant="h2" color="red" fontWeight="bold" sx={{ mb: "50px", textAlign: "center" }}>
						Erro ao consultar base de dados
					</Typography>
				</Box>
			)}

			{romaneioAntecipacaoViz && (
				<Box
					display="grid"
					gridTemplateColumns="repeat(12,minmax(100px, 1fr))"
					gridAutoRows="140px"
					position
					gap="20px"
					marginBottom="2px"
				>
					<StatBox
						span="6"
						BigNumber={prettyNumber(romaneioAntecipacaoViz.data.totalAntecipado[0].total_valor_compra)}
						BigNumberType="cents"
						title="Total Antecipado"
						percNumber=""
						icon={<PaidIcon sx={{ color: theme.palette.secondary.main, fontSize: "26px" }} />}
					/>
					<StatBox
						span="6"
						BigNumber={romaneioAntecipacaoViz.data.cadastro.length}
						BigNumberType="count"
						title="Total de Entregadores"
						percNumber=""
						icon={<PaidIcon sx={{ color: theme.palette.secondary.main, fontSize: "26px" }} />}
					/>
				</Box>
			)}

			{romaneioAntecipacaoViz && romaneioAntecipacaoViz.data.cadastro.length !== 0 && (
				<Box gridColumn={`span 12`}>
					<Table
						checkBoxSelection={false}
						jsonData={romaneioAntecipacaoViz.data.cadastro}
						columns={columnsOfAntecipacoesTable}
						fileName="ListaEntregadoresRayo"
					/>
				</Box>
			)}
		</>
	);
};

export default MuveListaEntregadores;
