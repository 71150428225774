import React from "react";
import { Box, useTheme } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import PaidIcon from "@mui/icons-material/Paid";
import Header from "../../layoutComponents/Header";
import StatBox from "../../boxComponents/StatBox";
import Table from "../../tableComponents/Table";
import { prettyNumber } from "../../utilFunctions/mathUtils";

const RayoAntecipacaoViz = ({ romaneioAntecipacaoViz, datePicked, setDatePicker }) => {
	const theme = useTheme();
	const datetime = new Date().toISOString().slice(0, 10);

	const columnsOfAntecipacoesTable = [
		{ field: "id", headerName: "ID", flex: 1, hide: true },
		{ field: "cnpjCpf", headerName: "Documento", flex: 1 },
		{
			field: "valorFace",
			headerName: "Valor Face",
			flex: 1,
			valueFormatter: (params) => prettyNumber(params?.value, "cents"),
		},
		{
			field: "valorCompra",
			headerName: "Valor Compra",
			flex: 1,
			valueFormatter: (params) => prettyNumber(params?.value, "cents"),
		},
		{
			field: "dataRomaneio",
			headerName: "Data Romaneio",
			flex: 1,
			valueFormatter: (params) => params?.value.slice(0, 10),
		},
		{
			field: "dataVencimento",
			headerName: "Data Vencimento",
			flex: 1,
			valueFormatter: (params) => params?.value.slice(0, 10),
		},
	];

	return (
		<>
			<Box display="grid" gridTemplateColumns="repeat(12,minmax(100px, 1fr))" gap="20px" mt="60px" marginBottom="40px">
				<Box gridColumn="span 6">
					<Header title="Solicitações" subtitle="Antecipações solicitadas" />
				</Box>
				<Box gridColumn="span 6">
					<LocalizationProvider dateAdapter={AdapterDayjs}>
						<DatePicker
							format="YYYY-MM-DD"
							value={datePicked}
							minDate={dayjs(datetime).subtract(40, "days")}
							maxDate={dayjs(datetime).add(1, "days")}
							label="Data para verificar"
							onChange={(newValue) => setDatePicker(newValue)}
							slotProps={{
								textField: {
									readOnly: true,
								},
							}}
						/>
					</LocalizationProvider>
				</Box>
			</Box>

			{/* GRID SYSTEM */}

			{romaneioAntecipacaoViz && romaneioAntecipacaoViz.length != 0 && (
				<Box
					display="grid"
					gridTemplateColumns="repeat(12,minmax(100px, 1fr) )"
					gridAutoRows="140px"
					position
					gap="20px"
					marginBottom="2px"
				>
					{
						<StatBox
							span="6"
							BigNumber={romaneioAntecipacaoViz.summary.valorFace}
							BigNumberType="cents"
							title={`Valor Face acumulado`}
							percNumber=""
							icon={<PaidIcon sx={{ color: theme.palette.secondary.main, fontSize: "26px" }} />}
						/>
					}
					{
						<StatBox
							span="6"
							BigNumber={romaneioAntecipacaoViz.summary.valorCompra}
							BigNumberType="cents"
							title="Valor Compra acumulado"
							percNumber=""
							icon={<PaidIcon sx={{ color: theme.palette.secondary.main, fontSize: "26px" }} />}
						/>
					}
				</Box>
			)}

			{romaneioAntecipacaoViz &&
				romaneioAntecipacaoViz.length != 0 &&
				romaneioAntecipacaoViz.antecipacoes.length !== 0 && (
					<Box gridColumn={`span 6`}>
						<Table
							checkBoxSelection={false}
							jsonData={romaneioAntecipacaoViz.antecipacoes}
							columns={columnsOfAntecipacoesTable}
							fileName="SolicitacoesAntecipacoes"
						/>
					</Box>
				)}
		</>
	);
};

export default RayoAntecipacaoViz;
