import { createListMessage } from "./createListMessage";
import { createPdfLink } from "./createPdfLink";
import { formatTime } from "./formatTime";

const phone = "5511985744649";

const handleReceivedMessage = {
  text: (messageData) => JSON.parse(messageData.S).body,
  interactive: (messageData) => {
    const parsedMessage = JSON.parse(messageData.S);
    return parsedMessage.title + "\n" + parsedMessage.description;
  },
  image: () => "imagem",
  document: (request) => "pdf",
};

const handleSentMessage = {
  list: (request) => createListMessage(request),
  interactive: (request) => request.text.body,
  text: (request) => request.text.body,
  document: (request) => createPdfLink(request.document.link),
  template: (request) =>
    `${request.template.components[0].parameters[0].text} A antecipação das suas entregas ${request.template.components[0].parameters[1].text} do dia ${request.template.components[0].parameters[2].text} já está disponível. O pedido de antecipação pode ser feito até ${request.template.components[0].parameters[3].text}`,
};

export const handleMessages = (
  messagesDynamoDB,
  startTimestamp,
  endTimestamp
) => {
  const formatedMessages = {};
  for (const messageDynamoDB of messagesDynamoDB) {
    const phone = messageDynamoDB.phone.N;
    const status = messageDynamoDB.status.S;
    const messageType = messageDynamoDB.messageType.S;
    const isReceived = status === "received";
    const request = messageDynamoDB.request
      ? JSON.parse(messageDynamoDB.request.S)
      : null;
    const message = isReceived
      ? handleReceivedMessage[messageType](messageDynamoDB.message)
      : handleSentMessage[messageType](request);

    const me = !isReceived;
    const time = formatTime(Number(messageDynamoDB.moment.N));

    if (!formatedMessages[phone]) {
      formatedMessages[phone] = {
        message: [{ me, message, time, status }],
        phone,
        startTimestamp,
        endTimestamp,
      };
    } else {
      formatedMessages[phone].message.push({ me, message, time, status });
    }
  }

  return formatedMessages;
};
