import { Box, Button, TextField, Typography } from "@mui/material";
import { useState, useEffect, useContext } from "react";
import { ConversationContext } from "./context/ConversationContext";
import DeliveryManList from "./SideBar/DeliveryManList";
import DeliveryManInfo from "./SideBar/DeliveryManInfo";
import ConversationDetails from "./ConversationDetails";
import { getAllConversations } from "./service/getAllConversations";
import { getConversation } from "./service/getConversation";
import { useSelector } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { toSeconds } from "../utilFunctions/toSeconds";
import { apiPost } from "../utilFunctions/apiRequests";

export default function WhatsappChat({ fundName, theme }) {
  const [refreshList, setRefreshList] = useState(true);
  const [hasConversation, setHasConversation] = useState(false);
  const { setConversations, setConversation } = useContext(ConversationContext);
  const [selectedForm, setSelectedForm] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");

  const handleSelectSingle = () => setSelectedForm("single");
  const handleSelectAll = () => setSelectedForm("all");

  const accessToken = useSelector((state) => state.auth0.accessToken);
  const { logout } = useAuth0();

  const [deliveryManData, setDeliveryManData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (accessToken) {
      apiPost(
        "/cadastroInfoWA",
        {
          type: "all",
          dbName: fundName,
          ops: "muve",
          // phoneNumber: "556285806939",
        },
        accessToken,
        logout
      )
        .then((value) => {
          setDeliveryManData(value);
          setError(null);
        })
        .catch((err) => {
          console.error(err);
          setError("Erro ao carregar as informações.");
        });
    }
  }, [accessToken, logout]);
  // console.log("dados", deliveryManData);

  const handleConversationsSubmit = async (e) => {
    e.preventDefault();
    try {
      const dataConversations = await getAllConversations(
        fundName,
        toSeconds(start),
        toSeconds(end),
        accessToken,
        logout
      );
      setConversations(dataConversations);
      setHasConversation(true);
    } catch (error) {
      alert(error.message);
    }
  };

  const handleConversationSubmit = async (e) => {
    e.preventDefault();
    try {
      let conversationData = await getConversation(
        fundName,
        phoneNumber,
        toSeconds(start),
        toSeconds(end),
        accessToken,
        logout
      );

      await apiPost(
        "/cadastroInfoWA",
        {
          type: "individual",
          dbName: fundName,
          ops: "muve",
          phoneNumber: "556285806939",
        },
        accessToken,
        logout
      )
        .then((deliveryManData) => {
          conversationData = {
            ...conversationData,
            ...deliveryManData.cadastro[0],
          };

          setError(null);
        })
        .catch((err) => {
          console.error(err);
          setError("Erro ao carregar as informações.");
        });

      console.log(conversationData);
      setConversation(conversationData);
      setHasConversation(true);
    } catch (error) {
      alert(error.message);
    }
  };

  const handleLast30Minutes = () => {
    const now = new Date();
    const thirtyMinutesAgo = new Date(now.getTime() - 30 * 60 * 1000);

    const nowAdjusted = new Date(now.getTime() - 3 * 60 * 60 * 1000);
    const thirtyMinutesAgoAdjusted = new Date(
      thirtyMinutesAgo.getTime() - 3 * 60 * 60 * 1000
    );

    setEnd(nowAdjusted.toISOString().slice(0, 16));
    setStart(thirtyMinutesAgoAdjusted.toISOString().slice(0, 16));
  };

  useEffect(() => {
    setInterval(() => {
      setRefreshList((v) => !v);
    }, 20000);
  }, []);

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="100%"
        sx={{ padding: 4 }}
      >
        <Box
          component="form"
          onSubmit={
            selectedForm === "all"
              ? handleConversationsSubmit
              : handleConversationSubmit
          }
          width="100%"
          maxWidth="500px"
          p={4}
          bgcolor="#111b21"
          boxShadow={5}
          borderRadius={2}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h4"
            fontWeight="bold"
            color="text.primary"
            mb={2}
          >
            Escolha o Chat
          </Typography>

          <Button
            variant="contained"
            color="primary"
            onClick={handleSelectAll}
            fullWidth
            sx={{
              paddingY: 1.5,
              fontSize: "1.125rem",
              fontWeight: "medium",
            }}
          >
            Consultar Todos
          </Button>

          <Button
            variant="contained"
            onClick={handleSelectSingle}
            fullWidth
            sx={{
              paddingY: 1.5,
              fontSize: "1.125rem",
              fontWeight: "medium",
              mt: 2,
            }}
          >
            Consulta Individual
          </Button>
          <Typography variant="h5" color="text.primary" mb={2} mt={2}>
            Informações da Consulta
          </Typography>

          {selectedForm === "single" && (
            <TextField
              label="Número de Telefone"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              placeholder="(11) 98765-4321"
              fullWidth
              variant="outlined"
              size="small"
              margin="normal"
              required
            />
          )}

          <TextField
            fullWidth
            type="datetime-local"
            value={start}
            onChange={(e) => setStart(e.target.value)}
            variant="outlined"
            required
          />

          <TextField
            fullWidth
            type="datetime-local"
            value={end}
            onChange={(e) => setEnd(e.target.value)}
            variant="outlined"
            required
          />

          <Button
            onClick={handleLast30Minutes}
            fullWidth
            variant="contained"
            color="secondary"
            sx={{ mt: 2 }}
          >
            Últimos 30 minutos
          </Button>

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="success"
            sx={{ mt: 2 }}
          >
            Enviar
          </Button>
        </Box>
      </Box>
      {/* deliveryman list and conversation details */}
      <Box
        display="flex"
        flexDirection="row"
        alignItems="flex-start"
        bgcolor={theme.palette.primary.main}
        justifyContent="center"
        height="100vh"
        width="100%"
      >
        <Box
          height="100%"
          sx={{
            width: "30%",
            maxHeight: "100%",
            overflow: "auto",
          }}
        >
          {selectedForm === "all" && hasConversation && <DeliveryManList />}
          {selectedForm === "single" && hasConversation && <DeliveryManInfo />}
        </Box>

        <Box display="flex" justifyContent="center" width="100%" height="100%">
          {hasConversation && <ConversationDetails />}
        </Box>
      </Box>
    </>
  );
}
