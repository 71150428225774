import React from "react";
import { Box } from "@mui/material";
import MuveGraphStatistics from "../../global/opsSpecifics/muveComponents/muveGraphStatistics";
import RayoGraphStatistics from "../../global/opsSpecifics/rayoComponents/rayoGraphStatistics";
import IndriveGraphStatistics from "../../global/opsSpecifics/indriveComponents/indriveGraphStatistics";

const XamaGraphStatistics = ({ ops }) => {
	return (
		<Box m="20px">
			{(() => {
				switch (ops) {
					case "muve":
						return <MuveGraphStatistics />;
					case "rayo":
						return <RayoGraphStatistics />;
					case "indrive":
						return <IndriveGraphStatistics />;
					default:
						return null;
				}
			})()}
		</Box>
	);
};

export default XamaGraphStatistics;
