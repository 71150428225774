import React from "react";
import { Box } from "@mui/material";
import MuvePersonStatus from "../../global/opsSpecifics/muveComponents/muvePersonStatus";
import RayoPersonStatus from "../../global/opsSpecifics/rayoComponents/rayoPersonStatus";

const XamaPersonStatus = ({ ops }) => {
	return <Box m="20px">{ops === "muve" ? <MuvePersonStatus /> : ops === "rayo" ? <RayoPersonStatus /> : null}</Box>;
};

export default XamaPersonStatus;
