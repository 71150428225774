import { Box, Typography } from "@mui/material";
import { Check, DoneAll, Error } from "@mui/icons-material";

export const StatusEnum = {
  Received: "received",
  Delivered: "delivered",
  Read: "read",
  ToSent: "toSent",
  Sent: "sent",
  Failed: "failed",
};

export default function MessageBalloon(props) {
  const { me, message, time, status } = props;
  const flexAlignItems = me ? "flex-end" : "flex-start";
  const backgroundColor = me ? "#005c4b" : "#111b21";
  const borderRadius = me ? "0 16px 16px 16px" : "16px 0 16px 16px";

  const renderStatusIcon = () => {
    switch (status) {
      case StatusEnum.Read:
        return (
          <DoneAll
            sx={{ color: "#0000ff", fontSize: "16px", marginLeft: "4px" }}
          />
        );
      case StatusEnum.Delivered:
        return (
          <DoneAll
            sx={{ color: "#b0b0b0", fontSize: "16px", marginLeft: "4px" }}
          />
        );
      case StatusEnum.Sent:
        return (
          <Check
            sx={{ color: "#b0b0b0", fontSize: "16px", marginLeft: "4px" }}
          />
        );
      case StatusEnum.Failed:
        return (
          <Error
            sx={{ color: "#f44336", fontSize: "16px", marginLeft: "4px" }}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: flexAlignItems,
        width: "100%",
        marginBottom: "12px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minWidth: "5%",
          maxWidth: "65%",
          backgroundColor: backgroundColor,
          padding: "8px",
          borderRadius: borderRadius,
        }}
      >
        <Typography
          variant="body1"
          sx={{
            wordBreak: "break-word",
            color: "white",
            whiteSpace: "pre-line",
          }}
        >
          {message}
        </Typography>
        <Typography
          variant="caption"
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            marginTop: "4px",
            color: "rgba(255, 255, 255, 0.6)",
          }}
        >
          {time}
          {me && renderStatusIcon()}{" "}
          {/* Exibir ícone de status apenas para mensagens enviadas pelo usuário */}
        </Typography>
      </Box>
    </Box>
  );
}
