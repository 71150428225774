import { createContext, useState } from "react";

export const ConversationContext = createContext({
  conversationData: {},
  conversationsData: [],
  message: [],
  setConversation: (conversationData) => {},
  setConversations: (conversationsData) => {},
  setMessage: (message) => {},
});

export const ConversationProvider = ({ children }) => {
  const [conversationData, setConversationData] = useState({});

  const [conversationsData, setConversationsData] = useState([]);
  const [message, setMessageData] = useState([]);

  function setConversation(conversationData) {
    setConversationData(conversationData);
  }

  function setConversations(conversationsData) {
    setConversationsData(conversationsData);
  }

  function setMessage(message) {
    setMessageData(message);
  }

  function setConversationTime(conversationTime) {
    setMessageData(message);
  }

  return (
    <ConversationContext.Provider
      value={{
        conversationData,
        conversationsData,
        message,
        setConversation,
        setConversations,
        setMessage,
      }}
    >
      {children}
    </ConversationContext.Provider>
  );
};
