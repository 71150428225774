import React from "react";
import { Box } from "@mui/material";
import MuvePaymentVerify from "../../global/opsSpecifics/muveComponents/muvePaymentVerify";
import RayoPaymentVerify from "../../global/opsSpecifics/rayoComponents/rayoPaymentVerify";

const XamaPaymentVerify = ({ ops }) => {
	return <Box m="20px">{ops === "muve" ? <MuvePaymentVerify /> : ops === "rayo" ? <RayoPaymentVerify /> : null}</Box>;
};

export default XamaPaymentVerify;
