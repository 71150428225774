import { ColorModeContext, useMode } from "./theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { Routes, Switch, Route } from "react-router-dom";
import Topbar from "./global/layoutComponents/Topbar";
import Sidebar2 from "./global/layoutComponents/Sidebar";
import { useEffect, useState } from "react";
import { SidebarContextProvider } from "./global/layoutComponents/SidebarContext";
import { useAuth0 } from "@auth0/auth0-react";
import { auth0SliceActions } from "./global/redux/auth0";
import { useDispatch } from "react-redux";

import RondologDataVerification from "./scenes/RondologDataVerification";
import UploadPage from "./scenes/Upload";
import CheckoutRomaneioCte from "./scenes/CheckoutRomaneioCte";
import Entrypoint from "./scenes/entrypoint";
import Trades from "./scenes/Trades";
import GestorCheckoutResolution from "./scenes/GestorCheckoutResolution";
import GestorBaixaResolution from "./scenes/GestorBaixaResolution";
import RondologPaymentVerify from "./scenes/RondologPaymentVerify";
import GestorDocApproval from "./scenes/GestorDocApproval";
import GestorLiquidacao from "./scenes/GestorLiquidacao";
import XamaAntecipacaoViz from "./scenes/XamaAntecipacaoViz";
import XamaGraphStatistics from "./scenes/XamaGraphStatistics";
import XamaListaEntregadores from "./scenes/XamaListaEntregadores";
import XamaPersonStatus from "./scenes/XamaPersonStatus";
import XamaCheckout from "./scenes/XamaCheckout";
import XamaPaymentVerify from "./scenes/XamaPaymentVerify";
import XptoAntecipacaoViz from "./scenes/XptoAntecipacaoViz";
import XptoPersonStatus from "./scenes/XptoPersonStatus";
import XptoCheckout from "./scenes/XptoCheckout";
import XptoPaymentVerify from "./scenes/XptoPaymentVerify";
import Faq from "./scenes/faq";
import GestorWhatsapp from "./scenes/GestorWhatsapp";

function App() {
	const [theme, colorMode] = useMode();
	const [accessToken, setAcessToken] = useState(null);
	const { isAuthenticated, user, getAccessTokenSilently, logout } = useAuth0();
	const screens = process.env.REACT_APP_SCREENS.split(" ");
	const sidebarName = process.env.REACT_APP_NAME_SIDEBAR;
	const dispatch = useDispatch();

	if (!isAuthenticated) {
		console.log("Não autenticado");
		logout();
	}

	useEffect(() => {
		getAccessTokenSilently().then((r) => {
			dispatch(auth0SliceActions.updateAccessToken({ accessToken: r }));
		});
	}, [isAuthenticated]);

	dispatch(auth0SliceActions.updateUser({ user: user }));

	return (
		<ColorModeContext.Provider value={colorMode}>
			<ThemeProvider theme={theme}>
				<SidebarContextProvider>
					<CssBaseline />
					<div className="app">
						<Sidebar2 name={sidebarName} />
						<main className="content">
							<Topbar />
							<Routes>
								<Route path="/" element={<Faq />} />
								{screens.includes("rondologRomaneio") && (
									<Route
										path="/romaneio"
										element={
											<UploadPage
												key="romaneio"
												title="Upload Romaneio"
												subtitle="Arquivos com informações referentes ao Romaneio"
												docType="rondolog_romaneio"
												extensionFile=".xlsx"
												uploadTitle="Upload de Romaneio"
											/>
										}
									/>
								)}
								{screens.includes("rondologAgregado") && (
									<Route
										path="/cadastroagregado"
										element={
											<UploadPage
												key="cadastroagregado"
												title="Cadastro de Agregados"
												subtitle="Arquivos com informações referentes ao cadastro de Agregados"
												docType="rondolog_agregado"
												extensionFile=".xls"
												uploadTitle="Upload de Agregados"
											/>
										}
									/>
								)}

								{screens.includes("cteRondolog") && (
									<Route
										path="/cte"
										element={
											<UploadPage
												key="cte"
												title="Cadastro CT-e"
												subtitle="Arquivos XML das CT-e"
												docType="cte_xml_rondolog"
												extensionFile=".xml"
												uploadTitle="Upload de CT-e"
											/>
										}
									/>
								)}

								{screens.includes("muveFinanceiro") && (
									<Route
										path="/muveFinanceiro"
										element={
											<UploadPage
												key="muveFinanceiro"
												title="Muve Financeiro"
												subtitle="Arquivo entregador por período Muve"
												docType="muve_financeiro"
												extensionFile=".xls"
												uploadTitle="Upload Muve Financeiro"
											/>
										}
									/>
								)}

								{screens.includes("muveFinanceiroGarantido") && (
									<Route
										path="/muveFinanceiroGarantido"
										element={
											<UploadPage
												key="muveFinanceiroGarantido"
												title="Muve Garantido"
												subtitle="Arquivo entregador por período Garantido Muve"
												docType="muve_financeiro_garantido"
												extensionFile=".xlsx"
												uploadTitle="Upload Muve Financeiro Garantido"
											/>
										}
									/>
								)}

								{screens.includes("muvePedidos") && (
									<Route
										path="/muvePedidos"
										element={
											<UploadPage
												key="muvePedidos"
												title="Muve Pedidos"
												subtitle="Arquivo Pedidos Muve"
												docType="muve_pedidos"
												extensionFile=".xls"
												uploadTitle="Upload Muve Pedidos"
											/>
										}
									/>
								)}

								{screens.includes("xptoFinanceiroGarantido") && (
									<Route
										path="/xptoFinanceiroGarantido"
										element={
											<UploadPage
												key="xptoFinanceiroGarantido"
												title="Xpto Garantido"
												subtitle="Arquivo entregador por período Garantido XPTO"
												docType="xpto_financeiro_garantido"
												extensionFile=".xlsx"
												uploadTitle="Upload XPTO Financeiro Garantido"
											/>
										}
									/>
								)}

								{screens.includes("xptoPedidos") && (
									<Route
										path="/xptoPedidos"
										element={
											<UploadPage
												key="xptoPedidos"
												title="Xpto Pedidos"
												subtitle="Arquivo Pedidos XPTO"
												docType="xpto_pedidos"
												extensionFile=".xls"
												uploadTitle="Upload XPTO Pedidos"
											/>
										}
									/>
								)}

								{screens.includes("rondologDataVerification") && (
									<Route path="/verificacaodados" element={<RondologDataVerification />} />
								)}

								{screens.includes("gestorCheckout") && (
									<Route path="/checkoutResolution" element={<GestorCheckoutResolution />} />
								)}

								{screens.includes("gestorBaixa") && <Route path="/gestorBaixa" element={<GestorBaixaResolution />} />}

								{screens.includes("gestorDocApproval") && (
									<Route path="/gestorDocApproval" element={<GestorDocApproval />} />
								)}

								{screens.includes("gestorLiquidacao") && (
									<Route path="/gestorLiquidacao" element={<GestorLiquidacao />} />
								)}

								{screens.includes("rondologPaymentVerify") && (
									<Route path="/rondologPayments" element={<RondologPaymentVerify />} />
								)}

								{screens.includes("checkoutRomaneioCte") && (
									<Route path="/checkout" element={<CheckoutRomaneioCte />} />
								)}

								{screens.includes("xptoPaymentVerify") && (
									<Route path="/xptoPayments" element={<XptoPaymentVerify />} />
								)}

								{screens.includes("xptoAntecipacaoViz") && (
									<Route path="/antecipacaoViz" element={<XptoAntecipacaoViz />} />
								)}

								{screens.includes("trades") && <Route path="/trades" element={<Trades />} />}
								{screens.includes("negs") && (
									<Route
										path="/negs"
										element={
											<UploadPage
												key="negs"
												title="Negociações"
												subtitle="Upload de arquivos de negociações"
												docType="negs_file"
												extensionFile=".txt"
												uploadTitle="Upload Negociação"
											/>
										}
									/>
								)}

								{screens.includes("xptoPersonStatus") && <Route path="/personStatus" element={<XptoPersonStatus />} />}

								{screens.includes("xptoCheckout") && <Route path="/xptocheckout" element={<XptoCheckout />} />}

								{/* --------------------------------------------------------------------------------------------------- */}
								{screens.includes("rayoFinanceiroUpload") && (
									<Route
										path="/rayoFinanceiro"
										element={
											<UploadPage
												key="ifoodFinanceiro"
												title="Ifood Financeiro"
												subtitle="Arquivo Financeiro do Ifood"
												docType="ifood_financeiro"
												extensionFile=".csv"
												uploadTitle="Upload Ifood Financeiro"
											/>
										}
									/>
								)}

								{screens.includes("rayoPerformanceUpload") && (
									<Route
										path="/rayoPerformance"
										element={
											<UploadPage
												key="ifoodPerformance"
												title="Ifood Performance"
												subtitle="Arquivo Performance do Ifood"
												docType="ifood_performance"
												extensionFile=".csv"
												uploadTitle="Upload Ifood Performance"
											/>
										}
									/>
								)}

								{screens.includes("rayoCadastroUpload") && (
									<Route
										path="/rayoCadastro"
										element={
											<UploadPage
												key="ifoodCadastro"
												title="Ifood Cadastro"
												subtitle="Arquivo Cadastro dos Moto-Boys para Ifood"
												docType="ifood_cadastro"
												extensionFile=".csv"
												uploadTitle="Upload Ifood Cadastro"
											/>
										}
									/>
								)}
								{screens.includes("indriveRomaneioUpload") && (
									<Route
										path="/indriveRomaneioUpload"
										element={
											<UploadPage
												key="indriveRomaneio"
												title="InDrive Romaneio Upload"
												subtitle="Arquivo do romaneio de InDrive"
												docType="indrive_romaneio"
												extensionFile=".xlsx"
												uploadTitle="Upload Romaneio InDrive"
											/>
										}
									/>
								)}
								{screens.includes("rayoPaymentVerify") && (
									<Route path="/rayoPayments" element={<XamaPaymentVerify ops="rayo" />} />
								)}
								{screens.includes("muvePaymentVerify") && (
									<Route path="/muvePayments" element={<XamaPaymentVerify ops="muve" />} />
								)}

								{screens.includes("rayoCheckout") && (
									<Route path="/rayocheckout" element={<XamaCheckout ops="rayo" />} />
								)}

								{screens.includes("muveCheckout") && (
									<Route path="/muvecheckout" element={<XamaCheckout ops="muve" />} />
								)}

								{screens.includes("rayoAntecipacaoViz") && (
									<Route path="/rayoantecipacaoViz" element={<XamaAntecipacaoViz ops="rayo" />} />
								)}

								{screens.includes("muveAntecipacaoViz") && (
									<Route path="/muveantecipacaoViz" element={<XamaAntecipacaoViz ops="muve" />} />
								)}

								{screens.includes("rayoGraphStatistics") && (
									<Route path="/rayoGraphStatistics" element={<XamaGraphStatistics ops="rayo" />} />
								)}

								{screens.includes("muveGraphStatistics") && (
									<Route path="/muveGraphStatistics" element={<XamaGraphStatistics ops="muve" />} />
								)}

								{screens.includes("rayoListaEntregadores") && (
									<Route path="/rayoListaEntregadores" element={<XamaListaEntregadores ops="rayo" />} />
								)}

								{screens.includes("muveListaEntregadores") && (
									<Route path="/muveListaEntregadores" element={<XamaListaEntregadores ops="muve" />} />
								)}

								{screens.includes("rayoPersonStatus") && (
									<Route path="/rayoPersonStatus" element={<XamaPersonStatus ops="rayo" />} />
								)}

								{screens.includes("muvePersonStatus") && (
									<Route path="/muvePersonStatus" element={<XamaPersonStatus ops="muve" />} />
								)}
								{/* --------------------------------------------------------------------------------------------------- */}

								{screens.includes("gestorWhatsapp") && <Route path="/gestorWhatsapp" element={<GestorWhatsapp />} />}
								{/* <Route path="/faq" element={<Faq />} /> */}
							</Routes>
						</main>
					</div>
				</SidebarContextProvider>
			</ThemeProvider>
		</ColorModeContext.Provider>
	);
}

export default App;
