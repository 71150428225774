import React, { useState, useEffect } from "react";
import Header from "../../layoutComponents/Header";
import { Box, useTheme, Typography } from "@mui/material";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useSelector } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { apiPost } from "../../utilFunctions/apiRequests";
import LineChart from "../../graphicComponents/LineChart";
import LinePercentChart from "../../graphicComponents/LinePercentChart";
import { createGraphData } from "../../utilFunctions/createGraphData";
import { FaqQuestion } from "../../boxComponents/FaqQuestion";

const RayoGraphStatistics = () => {
	const theme = useTheme();
	const datetime = new Date().toISOString().slice(0, 10);
	const { logout } = useAuth0();
	const accessToken = useSelector((state) => state.auth0.accessToken);

	const [datePicked, setDatePicker] = useState(dayjs(datetime));
	const [statisticsData, setStatisticsData] = useState({
		antecipacaoDia: null,
		ticketMedioDia: null,
		totalAntecipadoDia: null,
		aderencia: null,
		cadAprovadosDia: null,
	});

	useEffect(() => {
		const fetchData = async () => {
			if (datePicked && accessToken) {
				try {
					const types = [
						"antecipacao_dia",
						"ticket_medio_dia",
						"total_antecipado_dia",
						"aderencia",
						"cad_aprovados_dia",
					];

					const [antecipacaoDia, ticketMedioDia, totalAntecipadoDia, aderencia, cadAprovadosDia] = await Promise.all(
						types.map((type) =>
							apiPost(
								"/romaneioStatistics",
								{
									filterDate: datePicked.format("YYYY-MM-DD"),
									type,
									ops: "rayo",
								},
								accessToken,
								logout
							)
						)
					);

					setStatisticsData({
						antecipacaoDia,
						ticketMedioDia,
						totalAntecipadoDia,
						aderencia,
						cadAprovadosDia,
					});
				} catch (error) {
					console.error("API Error:", error);
				}
			}
		};

		fetchData();
	}, [accessToken, datePicked, logout]);

	const { antecipacaoDia, ticketMedioDia, totalAntecipadoDia, aderencia, cadAprovadosDia } = statisticsData;

	return (
		<Box m="20px">
			<Box display="grid" gridTemplateColumns="repeat(12,minmax(100px, 1fr))" gap="20px" mt="60px" marginBottom="40px">
				<Box gridColumn="span 6">
					<Header title="Estatísticas" subtitle="Estatísticas diárias dos Romaneios" />
				</Box>

				<Box gridColumn="span 6">
					<LocalizationProvider dateAdapter={AdapterDayjs}>
						<DatePicker
							format="YYYY-MM-DD"
							value={datePicked}
							minDate={dayjs(datetime).subtract(40, "days")}
							maxDate={dayjs(datetime).add(1, "days")}
							label="Data para verificar"
							onChange={(newValue) => {
								setStatisticsData({
									antecipacaoDia: null,
									ticketMedioDia: null,
									totalAntecipadoDia: null,
									aderencia: null,
									cadAprovadosDia: null,
								});
								setDatePicker(newValue);
							}}
						/>
					</LocalizationProvider>
				</Box>
			</Box>

			{/* FAQ Section */}
			<FaqQuestion
				question="O que é aderência?"
				answer="A aderência é a porcentagem de entregadores que anteciparam em relação ao total de entregadores que realizaram corrida"
			/>

			<FaqQuestion
				question="O que é Total antecipado do dia?"
				answer="É o valor antecipado aos entregadores, ou seja, o valor de compra."
			/>

			<FaqQuestion
				question="Total de entregadores é referente a quê?"
				answer="Ao total de entregadores únicos registrado no arquivos de pedidos."
			/>

			<FaqQuestion
				question="O ticket médio é referente a qual valor?"
				answer="O ticket médio é referente ao valor de Face. É desta forma para verificar se o valor do deságio está apropriado ao valor de face do crédito."
			/>

			<Box marginBottom="5%" marginTop="1%">
				<hr
					style={{
						border: "dotted 2px",
						color: theme.palette.secondary.main,
						backgroundColor: theme.palette.primary.main,
					}}
				></hr>
			</Box>

			<Box
				display="grid"
				gridTemplateColumns="repeat(12,minmax(100px, 1fr) )"
				gridColumn="span 12"
				gridAutoRows="200px"
				gap="20px"
				marginBottom="30%"
			>
				{antecipacaoDia?.data?.length > 0 && (
					<Box gridColumn={"span 6"}>
						<Typography
							variant="h3"
							color={theme.palette.neutral.light}
							fontWeight="bold"
							sx={{ mb: "20px", textAlign: "center" }}
						>
							Quantidade de Antecipações por Dia
						</Typography>
						<LineChart
							data={[
								createGraphData(
									antecipacaoDia.data,
									"data_romaneio",
									"n_antecipacoes",
									"Data",
									"Quantidade",
									"data",
									"asIs",
									"data",
									"asIs",
									"#365072",
									"#001f3f"
								),
							]}
							xTitle="Data"
							yTitle="Antecipações"
							yMin={0}
							yMax={100}
						/>
					</Box>
				)}
				{totalAntecipadoDia?.data?.length > 0 && (
					<Box gridColumn={"span 6"}>
						<Typography
							variant="h3"
							color={theme.palette.neutral.light}
							fontWeight="bold"
							sx={{ mb: "20px", textAlign: "center" }}
						>
							Total Antecipado no Dia
						</Typography>
						<LineChart
							data={[
								createGraphData(
									totalAntecipadoDia.data,
									"data_romaneio",
									"valor_antecipado_compra",
									"Data",
									"Total Antecipado",
									"data",
									"cent_to_unit",
									"data",
									"cents",
									"#365072",
									"#001f3f"
								),
							]}
							xTitle="Data"
							yTitle="Valor Antecipado (R$)"
							yMin={0}
							yMax={20000}
						/>
					</Box>
				)}
			</Box>

			<Box
				display="grid"
				gridTemplateColumns="repeat(12,minmax(100px, 1fr) )"
				gridAutoRows="140px"
				gap="20px"
				marginBottom="30%"
			>
				{aderencia?.data?.length > 0 && (
					<Box gridColumn={"span 6"}>
						<Typography
							variant="h3"
							color={theme.palette.neutral.light}
							fontWeight="bold"
							sx={{ mb: "20px", textAlign: "center" }}
						>
							Aderência dos Entregadores
						</Typography>
						<LinePercentChart
							data={[
								createGraphData(
									aderencia.data,
									"data_romaneio",
									"aderencia",
									"Data",
									"Aderencia",
									"data",
									"asIs",
									"data",
									"percent",
									"#365072",
									"#001f3f"
								),
							]}
							xTitle="Data"
							yTitle="Aderência: (%)"
						/>
					</Box>
				)}

				{aderencia?.data?.length > 0 && (
					<Box gridColumn={"span 6"}>
						<Typography
							variant="h3"
							color={theme.palette.neutral.light}
							fontWeight="bold"
							sx={{ mb: "20px", textAlign: "center" }}
						>
							Total de Entregadores
						</Typography>
						<LineChart
							data={[
								createGraphData(
									aderencia.data,
									"data_romaneio",
									"total_mb",
									"Data",
									"Total Entregadores",
									"data",
									"asIs",
									"data",
									"asIs",
									"#365072",
									"#001f3f"
								),
							]}
							xTitle="Data"
							yTitle="Total entregadores"
							yMin={0}
							yMax={150}
						/>
					</Box>
				)}
			</Box>

			<Box
				display="grid"
				gridTemplateColumns="repeat(12,minmax(100px, 1fr) )"
				gridAutoRows="140px"
				gap="20px"
				marginBottom="50%"
			>
				{cadAprovadosDia?.data?.length > 0 && (
					<Box gridColumn={"span 6"}>
						<Typography
							variant="h3"
							color={theme.palette.neutral.light}
							fontWeight="bold"
							sx={{ mb: "20px", textAlign: "center" }}
						>
							Quantidade Aprovados Dia
						</Typography>
						<LineChart
							data={[
								createGraphData(
									cadAprovadosDia.data,
									"data_aprovacao",
									"cadastros_aprovados",
									"Data",
									"Entregadores Aprovados",
									"data",
									"integer",
									"data",
									"integer",
									"#365072",
									"#001f3f"
								),
							]}
							xTitle="Data"
							yTitle="Entregadores Aprovados"
							yMin={0}
							yMax={100}
						/>
					</Box>
				)}

				{ticketMedioDia?.data?.length > 0 && (
					<Box gridColumn={"span 6"}>
						<Typography
							variant="h3"
							color={theme.palette.neutral.light}
							fontWeight="bold"
							sx={{ mb: "20px", textAlign: "center" }}
						>
							Média Diária do Ticket
						</Typography>
						<LineChart
							data={[
								createGraphData(
									ticketMedioDia.data,
									"data_romaneio",
									"ticker_medio",
									"Data",
									"Média",
									"data",
									"cent_to_unit",
									"data",
									"cents",
									"#365072",
									"#001f3f"
								),
							]}
							xTitle="Data"
							yTitle="Ticket (R$)"
							yMin={100}
							yMax={300}
						/>
					</Box>
				)}
			</Box>
		</Box>
	);
};

export default RayoGraphStatistics;
