import { useCallback, useContext, useState } from "react";
import { ConversationContext } from "../context/ConversationContext";
import Avatar from "../Avatar";
import { Box, Typography, Divider } from "@mui/material";

export default function ConversationList(props) {
  const { isFirstConversation, data } = props;
  const { setConversation, conversationsData } =
    useContext(ConversationContext);
  const { time, phone } = data;
  const [isHover, setHover] = useState(false);

  const handleConversationSelect = useCallback(() => {
    const conversation = conversationsData.find(
      (conversation) => conversation.phone === phone
    );
    if (!conversation) return window.alert("Conversa não encontrada");
    setConversation(conversation);
  }, [setConversation, conversationsData, phone]);

  return (
    <Box
      display="flex"
      alignItems="center"
      width="100%"
      height="80px"
      bgcolor={isHover ? "#2A3942" : "#111B21"}
      pl={3}
      pr={4}
      sx={{
        cursor: "pointer",
        "&:hover": { bgcolor: "#111b21" },
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={handleConversationSelect}
    >
      <Box width="4.8rem">
        <Avatar width="3rem" height="3rem" />
      </Box>
      <Box display="flex" flexDirection="column" width="100%">
        {!isFirstConversation && (
          <Divider sx={{ borderColor: "rgba(134,150,160,0.15)" }} />
        )}
        <Box display="flex" py={2} alignItems="center">
          <Box flexGrow={1}>
            <Typography
              variant="body1"
              sx={{
                color: "white",
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
              }}
            >
              {phone}
            </Typography>
          </Box>
          <Box color="#aebac1" textAlign="right">
            <Typography variant="caption">{time}</Typography>
            {isHover && (
              <Box display="flex" alignItems="center" justifyContent="center">
                <svg viewBox="0 0 19 20" width="19" height="20">
                  <path
                    fill="currentColor"
                    d="m3.8 6.7 5.7 5.7 5.7-5.7 1.6 1.6-7.3 7.2-7.3-7.2 1.6-1.6z"
                  ></path>
                </svg>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
