import { Box } from "@mui/material";

export default function Avatar(props) {
  const { width, height } = props;

  return (
    <Box
      sx={{
        borderRadius: "50%",
        width,
        height,
        overflow: "hidden",
      }}
    >
      <img
        src={`/assets/images/user.jpg`}
        alt="Avatar"
        width={width}
        height={height}
        style={{ borderRadius: "50%", objectFit: "cover" }}
      />
    </Box>
  );
}
