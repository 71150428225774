import Header from "../../global/layoutComponents/Header.jsx";
import { Box, useTheme } from "@mui/material";
import { SelectForm } from "../../global/inputComponents/SelectForm.jsx";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { apiGet } from "../../global/utilFunctions/apiRequests.js";
import WhatsappChat from "../../global/whatsappComponent/WhatsappChat.jsx";
import { ConversationProvider } from "../../global/whatsappComponent/context/ConversationContext.jsx";

const GestorWhatsapp = () => {
  const theme = useTheme();
  const { logout } = useAuth0();
  const accessToken = useSelector((state) => state.auth0.accessToken);

  const [fundName, setFundName] = useState("");
  const [fundOptions, setFundOptions] = useState([]);

  useEffect(() => {
    if (accessToken != "") {
      apiGet("/availableFunds", accessToken, logout)
        .then((value) => {
          value.map((e) => {
            delete Object.assign(e, { key: e.dbname })["dbname"];
            delete Object.assign(e, { value: e.fundname })["fundname"];
          });
          setFundOptions(value);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [accessToken, logout]);

  switch (fundName) {
    case "xpto_prod":
    case "xpto_dev":
      var content = (
        <ConversationProvider>
          <WhatsappChat
            fundName={fundName}
            setFundName={setFundName}
            fundOptions={fundOptions}
            theme={theme}
            logout={logout}
            accessToken={accessToken}
          />
        </ConversationProvider>
      );
      break;
    case "xama_prod":
    case "xama_dev":
      var content = (
        <ConversationProvider>
          <WhatsappChat
            fundName={fundName}
            setFundName={setFundName}
            fundOptions={fundOptions}
            theme={theme}
            logout={logout}
            accessToken={accessToken}
          />
        </ConversationProvider>
      );
      break;
    case "rondolog_prod":
    case "rondolog_dev":
      var content = (
        <ConversationProvider>
          <WhatsappChat
            fundName={fundName}
            setFundName={setFundName}
            fundOptions={fundOptions}
            theme={theme}
            logout={logout}
            accessToken={accessToken}
          />
        </ConversationProvider>
      );
      break;
    case "teste":
      var content = (
        <ConversationProvider>
          <WhatsappChat
            fundName={fundName}
            setFundName={setFundName}
            fundOptions={fundOptions}
            theme={theme}
            logout={logout}
            accessToken={accessToken}
          />
        </ConversationProvider>
      );

      break;
    default:
      var content = (
        <Box m="20px">
          <Header title="Selecione um Fundo" subtitle="" />
        </Box>
      );
  }

  return (
    <>
      <Box>
        <Box
          display="grid"
          gridTemplateColumns="repeat(12,minmax(100px, 1fr) )"
          gridAutoRows="0"
          position
          gap="20px"
          mt="60px"
          marginBottom="40px"
        >
          <Box
            gridColumn={`span 6`}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Header
                title="Mensagens do Whatsapp"
                subtitle="Ver mensagens do whatsapp"
              />
            </Box>
          </Box>

          <Box
            gridColumn={`span 6`}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <SelectForm
              label="Fundos"
              varValue={fundName}
              setFun={setFundName}
              optionArray={fundOptions}
            />
          </Box>
        </Box>
        <>{content}</>
      </Box>
    </>
  );
};

export default GestorWhatsapp;
