import { Box, Typography } from "@mui/material";
import { useContext } from "react";
import { ConversationContext } from "../context/ConversationContext";

export default function DeliveryManInfo() {
  const { conversationData } = useContext(ConversationContext);
  const {
    nome_completo,
    telefone,
    chave_pix,
    address,
    numero_documento,
    status,
    lgpdAgreement,
  } = conversationData;

  return (
    <Box height="100%" bgcolor="#111b21" padding={3}>
      <Typography variant="h5" fontWeight="bold" mb={2}>
        Informações do Entregador
      </Typography>

      <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
        <Typography>
          <strong>Nome:</strong> {nome_completo}
        </Typography>
        <Typography>
          <strong>CPF/CNPJ:</strong> {numero_documento}
        </Typography>
        <Typography>
          <strong>Chave Pix:</strong> {chave_pix}
        </Typography>
        <Typography>
          <strong>Telefone:</strong> {telefone}
        </Typography>
        <Typography>
          <strong>Endereço:</strong> {address}
        </Typography>
        <Typography>
          <strong>Status:</strong> {status}
        </Typography>
        <Typography color={lgpdAgreement ? "green.600" : "red.600"}>
          <strong>Acordo LGPD:</strong>{" "}
          {lgpdAgreement ? "Aceito" : "Não Aceito"}
        </Typography>
      </Box>
    </Box>
  );
}
