import { Box, TextField, IconButton } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ConversationList from "../ConversationList";
import { useContext, useEffect, useState } from "react";
import { ConversationContext } from "../context/ConversationContext";

export default function DeliveryManList() {
  const [conversationsList, setConversationsList] = useState([]);
  const { conversationsData } = useContext(ConversationContext);

  useEffect(() => {
    setConversationsList(conversationsData);
  }, [conversationsData]);

  const [search, setSearch] = useState("");
  const filteredConversationsList =
    search.length > 0
      ? conversationsList.filter((conversation) => {
          return conversation.phone.toLowerCase().includes(search);
        })
      : conversationsList;

  return (
    <Box display="flex" flexDirection="column" height="100%" bgcolor="#111b21">
      <Box display="flex" bgcolor="#111b21" py={2} pl={4} alignItems="center">
        <TextField
          variant="outlined"
          placeholder="Pesquisar conversa"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          InputProps={{
            style: {
              backgroundColor: "#202c33",
              color: "white",
              paddingLeft: "10px",
            },
          }}
          inputProps={{
            style: {
              color: "white",
            },
          }}
        />{" "}
        <IconButton
          sx={{
            marginLeft: "4px",
            color: "#AEBAC1",
            marginRight: 2,
          }}
        >
          <SearchIcon />
        </IconButton>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
        overflow="auto"
        id="conversation"
      >
        {filteredConversationsList.map((conversation, index) => (
          <ConversationList
            key={index}
            isFirstConversation={index === 0}
            data={conversation}
          />
        ))}
      </Box>
    </Box>
  );
}
