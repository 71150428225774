import { Link } from "@mui/material";

export const createPdfLink = (link) => {
  return (
    <Link
      href={link}
      target="_blank"
      rel="noopener noreferrer"
      color="blue"
      underline="hover"
    >
      link pdf
    </Link>
  );
};
