import axios from "axios";
import { logoutIf401 } from "./logoutIf400";

export const apiPost = (endpoint, payload, apiToken, logout, url = process.env.REACT_APP_DASHBOARD_API) => {
  try {
    const token = apiToken;
    if (token != null) {
      const data = axios
        .post(url + endpoint, payload, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res.data.data != undefined) {
            return res.data.data;
          } else {
            return res.data;
          }
        })
        .catch((err) => {
          console.log(err);
          logoutIf401(err, logout, token);
          return [];
        });

      return data;
    }
  } catch (err) {
    console.log(err);
  }
  return null;
};

export const apiGet = (endpoint, apiToken, logout, url = process.env.REACT_APP_DASHBOARD_API) => {
  try {
    const token = apiToken;
    if (token != null) {
      const data = axios
        .get(url + endpoint, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res.data.data != undefined) {
            return res.data.data;
          } else {
            return res.data;
          }
        })
        .catch((err) => {
          logoutIf401(err, logout, token);
          return [];
        });

      return data;
    }
  } catch (err) {
    console.log(err);
  }
  return null;
};
