export const formatTime = (seconds) => {
  // Ajustar fuso horário para o Brasil (3 horas a menos)
  seconds -= 3 * 3600;

  // Criar um objeto Date com o tempo ajustado
  const date = new Date(seconds * 1000); // Converter segundos para milissegundos

  // Obter componentes da data
  const day = String(date.getUTCDate()).padStart(2, "0");
  const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Meses começam em 0
  const year = date.getUTCFullYear();

  // Obter horas e minutos formatados
  const hours = String(date.getUTCHours()).padStart(2, "0");
  const minutes = String(date.getUTCMinutes()).padStart(2, "0");

  // Retornar a string formatada
  return `${day}/${month}/${year} ${hours}:${minutes}`;
};
