import React from "react";
import { Box } from "@mui/material";
import MuveListaEntregadores from "../../global/opsSpecifics/muveComponents/muveListaEntregadores";
import RayoListaEntregadores from "../../global/opsSpecifics/rayoComponents/rayoListaEntregadores";

const XamaListaEntregadores = ({ ops }) => {
	return (
		<Box m="20px">{ops === "muve" ? <MuveListaEntregadores /> : ops === "rayo" ? <RayoListaEntregadores /> : null}</Box>
	);
};

export default XamaListaEntregadores;
