import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { Box, useTheme } from "@mui/material";
import { apiPost } from "../../global/utilFunctions/apiRequests";
import Header from "../../global/layoutComponents/Header";
import MuveCheckout from "../../global/opsSpecifics/muveComponents/muveCheckout";
import RayoCheckout from "../../global/opsSpecifics/rayoComponents/rayoCheckout";
import IndriveCheckout from "../../global/opsSpecifics/indriveComponents/indriveCheckout";

const XamaCheckout = ({ ops }) => {
	const theme = useTheme();
	const { logout } = useAuth0();
	const accessToken = useSelector((state) => state.auth0.accessToken);

	const [firstSearch, setFirstSearch] = useState(false);
	const [checkoutList, setCheckoutList] = useState([]);
	const [refreshList, setRefreshList] = useState(true);

	useEffect(() => {
		let interval;
		if (accessToken !== "" && (ops === "muve" || ops === "rayo" || ops === "indrive")) {
			const fetchData = () => {
				apiPost(
					"/romaneioCheckoutSummary",
					{
						dbName: "xama",
						ops: ops,
					},
					accessToken,
					logout
				)
					.then((value) => {
						setCheckoutList(value);
						setFirstSearch(true);
					})
					.catch((err) => {
						console.log(err);
					});
			};

			fetchData();

			interval = setInterval(() => {
				fetchData();
			}, 20000);
		}

		return () => clearInterval(interval);
	}, [accessToken, logout, ops]);

	return (
		<>
			{ops === "rayo" && (
				<RayoCheckout
					checkoutList={checkoutList}
					refreshList={refreshList}
					setRefreshList={setRefreshList}
					firstSearch={firstSearch}
				/>
			)}
			{ops === "muve" && (
				<MuveCheckout
					checkoutList={checkoutList}
					refreshList={refreshList}
					setRefreshList={setRefreshList}
					firstSearch={firstSearch}
				/>
			)}
			{ops === "indrive" && (
				<IndriveCheckout
					checkoutList={checkoutList}
					refreshList={refreshList}
					setRefreshList={setRefreshList}
					firstSearch={firstSearch}
				/>
			)}
		</>
	);
};

export default XamaCheckout;
